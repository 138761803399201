







import { Component, Prop, Vue } from "vue-property-decorator";
import axios, { APIResponse } from "@/plugins/axios";
import AlertModule, { AlertType } from "@/store/modules/alert";
import ConfiguratorModule from "@/store/modules/configurator";
import ConfiguratorConfigurationItemSelectionList from "../item-selection/ConfiguratorConfigurationItemSelectionList.vue";
import {
    ConfiguratorArticle,
    ConfiguratorArticleGroup,
} from "@/store/modules/configurator";
import { ConfiguratorConfigurationSelectionItem } from "@/types/configurator";

/**
 * ConfiguratorConfigurationStepByGroups component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ConfiguratorConfigurationItemSelectionList,
    },
})
export default class ConfiguratorConfigurationStepByGroups extends Vue {
    @Prop({ type: Array, required: true })
    readonly articleGroups!: ConfiguratorArticleGroup[];

    // TODO use value prop and @input event
    private selectedArticleGroup: ConfiguratorArticleGroup | null = null;

    /**
     * @returns items for item selection list component
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get itemSelectionListItems(): ConfiguratorConfigurationSelectionItem[] {
        // Return articles when no group is selected
        if (this.selectedArticleGroup === null) {
            return this.articleGroups.map((ag) => ({
                id: ag.id,
                name: ag.names[0].name,
                image: ag.image,
                cbData: ag,
            }));
        }

        // Return children if the array is not empty
        if (
            Array.isArray(this.selectedArticleGroup.children) &&
            this.selectedArticleGroup.children.length > 0
        ) {
            return this.selectedArticleGroup.children.map((ag) => ({
                id: ag.id,
                name: ag.names[0].name,
                image: ag.image,
                cbData: ag,
            }));
        }

        // Return articles if the array is not empty
        if (this.selectedArticleGroup.articles) {
            return this.selectedArticleGroup.articles.map((article) => ({
                id: article.id,
                name: article.descriptions[0].title,
                image: article.images[0],
                cbData: article,
            }));
        }

        return [];
    }

    /**
     * Handler for item selection
     *
     * @param articleGroup selected article group
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async itemSelected(
        id: number,
        item: ConfiguratorArticle | ConfiguratorArticleGroup
    ) {
        // Check if Group/subgroup was clicked
        if (
            this.selectedArticleGroup === null ||
            this.selectedArticleGroup.children.length > 0
        ) {
            // Select group/subgroup
            this.selectedArticleGroup = item as ConfiguratorArticleGroup;

            // Check if selected group is the last group (no children)
            if (this.selectedArticleGroup.children.length === 0) {
                // Searching for group articles
                try {
                    const response = (
                        await axios.post<APIResponse<ConfiguratorArticle[]>>(
                            `/configurator/group/${ConfiguratorModule.selectedConfigurationGroupId}/article/group/${id}`,
                            {
                                configuration: ConfiguratorModule.configuration,
                                currentStep: ConfiguratorModule.currentStep,
                            }
                        )
                    ).data;
                    if (response.status === "error") {
                        throw new Error(response.message || "unknownError");
                    }

                    // Emit groupSelect event if no articles found
                    if (response.data.length === 0) {
                        this.reset();
                        this.$emit("groupSelect", item);
                    }
                    // If articles found, the selectedArticleGroup articles will be updated and shown to the user
                    else {
                        Vue.set(
                            this.selectedArticleGroup,
                            "articles",
                            response.data
                        );
                    }
                } catch (err) {
                    const errorMessage =
                        err instanceof Error ? err.message : (err as string);

                    AlertModule.showAlert({
                        type: AlertType.ERROR,
                        message: errorMessage,
                    });
                }
            }
        }
        // Article clicked
        else {
            this.reset();
            this.$emit("articleSelect", item);
        }
    }

    private reset() {
        this.selectedArticleGroup = null;
    }
}
