







































































































































import { Component, Vue } from "vue-property-decorator";
import {
    ConfiguratorConfigurationStep,
    ConfiguratorConfigurationStepType,
} from "@/store/modules/configurator/types";
import ConfiguratorModule, {
    ConfiguratorArticle,
    ConfiguratorArticleGroup,
    ConfiguratorArticleProperty,
} from "@/store/modules/configurator";
import { Property } from "@/types/property";
import ConfiguratorConfigurationItemSelectionList from "@/components/configurator/configuration/step/item-selection/ConfiguratorConfigurationItemSelectionList.vue";
import ConfiguratorConfigurationStepByGroups from "@/components/configurator/configuration/step/group/ConfiguratorConfigurationStepByGroups.vue";
import ConfiguratorConfigurationStepByProperty from "@/components/configurator/configuration/step/property/ConfiguratorConfigurationStepByProperty.vue";

/**
 * Configurator view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ConfiguratorConfigurationItemSelectionList,
        ConfiguratorConfigurationStepByGroups,
        ConfiguratorConfigurationStepByProperty,
    },
})
export default class ConfiguratorArticleConfigurationSteps extends Vue {

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        if (ConfiguratorModule.selectedCategoryId === -1) {
            ConfiguratorModule.loadLastConfigurationState();
        }
    }

    /**
     * @param id id
     *
     * @returns all configuration property items by id
     */
    private configurationPropertyItemsById(
        id: number
    ): ConfiguratorArticleProperty[] {
        const properties: ConfiguratorArticleProperty[] = [];

        const configurationPropertyStep:
            | ConfiguratorConfigurationStep<Property>
            | undefined = ConfiguratorModule.configurationSteps
            .filter(
                (step) =>
                    step.type ===
                    ConfiguratorConfigurationStepType.GlobalProperty
            )
            .find((step) => step.data.id === id);
        if (!configurationPropertyStep) return [];

        configurationPropertyStep.data.units.forEach((unit) => {
            properties.push({
                id: configurationPropertyStep.data.id,
                names: [...configurationPropertyStep.data.names],
                unit: unit,
                value: null,
            });
        });

        return properties;
    }

    /*
     * @returns confiugration steps
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationSteps(): ConfiguratorConfigurationStep<any>[] {
        return ConfiguratorModule.configurationSteps.filter(
            (step) => step.order != null
        );
    }

    /**
     * @returns currentStep of ConfiguratorModule
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get currentStep(): number {
        return ConfiguratorModule.currentStep;
    }

    /**
     * Sets currentStep with mutation and saves configuration state
     *
     * @param step step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private set currentStep(step: number) {
        ConfiguratorModule.jumpToStep(step);
    }

    /**
     * Jumps to next step
     * If current step is the last step, the overview page will be shown
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private nextStep() {
        ConfiguratorModule.nextStep();
        if (
            this.configurationSteps.length > 0 &&
            ConfiguratorModule.currentStep - 1 ==
                this.configurationSteps.length + 2
        ) {
            this.$router.push({ name: "configuratorOverview" });
        }
    }

    /**
     * Handler when article was selected
     * Set article for current step and go to next step
     *
     * @param article article
     * @param order order
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async articleSelected(
        article: ConfiguratorArticle,
        order: number | null
    ) {
        await ConfiguratorModule.setArticleForCurrentStep({ article, order });
        await ConfiguratorModule.validateConfigurationStepResults();
        this.nextStep();
    }

    /**
     * Handler when group was selected
     * Set group for current step and go to next step
     *
     * @param group group
     * @param order order
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async groupSelected(
        group: ConfiguratorArticleGroup,
        order: number | null
    ) {
        await ConfiguratorModule.setGroupForCurrentStep({ group, order });
        await ConfiguratorModule.validateConfigurationStepResults();
        this.nextStep();
    }

    /**
     * Handler when global property was selected
     * Set group for current step and go to next step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async globalPropertySelected(
        property: ConfiguratorArticleProperty
    ) {
        await ConfiguratorModule.updateConfigurationProperty(property);
        await ConfiguratorModule.validateConfigurationStepResults();
        this.nextStep();
    }

    /**
     * @returns ConfiguratorModule for template
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get ConfiguratorModule() {
        return ConfiguratorModule;
    }

    /**
     * @returns configuration step types
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationStepTypes() {
        return ConfiguratorConfigurationStepType;
    }
}
