var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$tc("configurator.step", 100)))]),_c('v-stepper',{key:!!_vm.configurationSteps.length,attrs:{"vertical":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=_vm._n($$v)},expression:"currentStep"}},[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.ConfiguratorModule.selectedCategoryId !== -1}},[_vm._v(" "+_vm._s(_vm.$t("configurator.step.data.category"))+" ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('ConfiguratorConfigurationItemSelectionList',{attrs:{"items":_vm.ConfiguratorModule.categories.map(function (cat) { return ({
                        id: cat.id,
                        name:
                            cat.names.length > 0
                                ? cat.names[0].name
                                : 'N/A',
                        image: cat.image,
                    }); })},on:{"click":_vm.ConfiguratorModule.selectCategory}})],1),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.ConfiguratorModule.selectedConfigurationGroupId !== -1}},[_vm._v(" "+_vm._s(_vm.$t("configurator.step.data.configuration"))+" ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('ConfiguratorConfigurationItemSelectionList',{attrs:{"items":_vm.ConfiguratorModule.configurationGroups.map(function (conf) { return ({
                        id: conf.id,
                        name: conf.names[0].name,
                        image: conf.image,
                    }); })},on:{"click":_vm.ConfiguratorModule.selectConfigurationGroup}})],1),_vm._l((_vm.configurationSteps),function(step){return [[_c('v-stepper-step',{key:("v-stepper-step-" + (step.order)),attrs:{"step":step.order,"complete":_vm.ConfiguratorModule.completedSteps >= step.order,"editable":_vm.ConfiguratorModule.completedSteps >= step.order}},[(
                            step.type ===
                                _vm.configurationStepTypes.GlobalProperty &&
                            'names' in step.data
                        )?_c('span',[_vm._v(" "+_vm._s(step.data.names[0].name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("configurator.step.data." + step.type))+" ")])]),_c('v-stepper-content',{key:("v-stepper-content-" + (step.order)),attrs:{"step":step.order}},[(
                            step.type === _vm.configurationStepTypes.Groups &&
                            Array.isArray(step.data)
                        )?_c('ConfiguratorConfigurationStepByGroups',{attrs:{"articleGroups":step.data},on:{"articleSelect":function (article) { return _vm.articleSelected(article, step.order); },"groupSelect":function (group) { return _vm.groupSelected(group, step.order); }}}):(
                            step.type ===
                                _vm.configurationStepTypes.GlobalProperty &&
                            !Array.isArray(step.data)
                        )?_c('ConfiguratorConfigurationStepByProperty',{attrs:{"properties":_vm.configurationPropertyItemsById(step.data.id),"value":_vm.ConfiguratorModule.configuration.properties.find(
                                function (cp) { return cp.id === step.data.id; }
                            )},on:{"input":_vm.globalPropertySelected,"applyClick":_vm.globalPropertySelected}}):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]]})],2),(
            _vm.configurationSteps.length > 0 &&
            _vm.ConfiguratorModule.completedSteps ==
                _vm.configurationSteps.length + 2
        )?_c('v-btn',{staticClass:"mt-5",attrs:{"to":{ name: 'configuratorOverview' },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("configurator.goToOverview"))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }